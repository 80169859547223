import React, { useState, useRef, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MainLayoutComponent from '../../../common/layout/components/main.layout.component';
import { context as ProcessContext } from '../../../context/process.context';
import { NavLink, Link, useSearchParams } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import MainProcessEnergyUseComponent from '../../../common/layout/components/main.process.energy.use.component';
import MainInstantaneousPowerComponent from '../../../common/layout/components/main.instantaneous.power.component';
import MainTotalEnergyUseComponent from '../../../common/layout/components/main.total.energy.use.component';
import MainProcessInstantaneousPowerComponent from '../../../common/layout/components/main.process.instantaneous.power.component';
import ReactGA from 'react-ga4';
import KpiMachineLayoutComponent from '../../../common/layout/components/kpi.machine.layout.component';
import ChangePageViewComponent from '../../../common/layout/components/change.page.view.component';
import MachineStatusDetailContainer from '../../machinestatus/containers/machine.status.detail.container';
import { useDispatch, useSelector } from 'react-redux';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { machineStatusEnabled } from '../../../redux/company/company.action';
import { getLayerId, getLayerType } from '../../../common/functions/commonFunctions';


function ProcessLayerDetailComponent() {
  const energyRef = useRef();
  const processRef = useRef();
  const peakRef = useRef();
  const instantRef = useRef();
  let [searchParams] = useSearchParams();

  const process_type = searchParams.get('process_type');
  const process_id = searchParams.get('process_id');
  const plant_id = searchParams.get('plant_id');
  const plant_code = searchParams.get('plant_code');
  let parentPlantDetails = useContext(ProcessContext).state.sidebarLinks?.layers?.filter((plants)=>
    plants.layer_id==plant_id
  )[0];

 let  processList= parentPlantDetails?.layers
  let chartWindows;
  processList = processList?.map((process) => ({
    name: process.name,
    process_id: process.layer_id,
    process_type: process.layer_type,
    sub_process_id: process.sub_process_id,
    is_zone_enabled: process.ugn_zones ? true : false,
    plant_code:process?.plant_code,
    layer_type:process?.layer_type
  }));

  const isMachineStatusEnabled = useSelector((state) => state.company.is_machine_status_enabled);
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')


  const sub_process_id = searchParams.get('sub_process_id');
  // const is_zone_enabled = searchParams.get('is_zone_enabled');
  const kpi_view = searchParams.get('kpi');
  const machine_status_view = searchParams.get('machine_status');
  //const energy_view = searchParams.get('energy');


  // const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false

  const [payload, setPayload] = useState(null);
  const [data] = useHttpApiCall(payload);
  const dispatch = useDispatch();
 
 
  useEffect(()=>{
   if(flexibleEquipmentEnabled){
     setPayload({
       auth: true,
       url: 'v0/layers/parent-companies/basic',
       params:{
         layer_type:'plant',
         plant_code:plant_code
       }
     });
   }
 },[plant_code])

 useEffect(()=>{
  if(flexibleEquipmentEnabled){
    if (data?.data?.data?.company_info?. kpi_view == 'true') {
      pageViewObject = {
        energy: false,
        kpi: true
      };
    } else if (data?.data?.data?.company_info?. is_machine_status_enabled == true) {
    dispatch(machineStatusEnabled(data?.data?.data?.company_info?. is_machine_status_enabled));
    // dispatch(machineStatusEnabled(false));


      pageViewObject = {
        energy: false,
        machine_status: true,
        kpi: false
      };
    } else {
      pageViewObject = {
        energy: true,
        kpi: false,
        // machine_status: true,
      };
    }
    // setPageView(pageViewObject)
  }
 
},[data])
 
  let pageViewObject;

  if (kpi_view == 'true') {
    pageViewObject = {
      energy: false,
      kpi: true
    };
  } else if (machine_status_view == 'true') {
    pageViewObject = {
      energy: false,
      machine_status: true,
      kpi: false
    };
  } else {
    pageViewObject = {
      energy: true,
      kpi: false
    };
  }

  const [pageView, setPageView] = useState(pageViewObject);

  const { process } = useParams();

  //const [currentProcessIndex,setCurrentProcessIndex]= useState(getInitialIndex(processList,searchParams.get('process_id'),searchParams.get('sub_process_id'),searchParams.get('process_type')))
  chartWindows = [
    {
      ref: energyRef,
      component: () => {
        return [
          MainTotalEnergyUseComponent,
          {
            drilldownlevel: 'ProcessLayer',
            process_type: process_type,
            process_id: process_id,
            plant_id:plant_id,
            plant_code:plant_code,
            layer_id:process_id,
            sub_process_id: sub_process_id,
            graph_name: 'Total energy use (kWh)',
            graph_tooltip:
              'The amount of power consumed by all measured equipment within this process.'
          }
        ];
      },
      id: 'energyProcess'
    },
    {
      ref: processRef,
      component: () => {
        return [
          MainProcessEnergyUseComponent,
          {
            drilldownlevel: 'ProcessLayer',
            process_type: process_type,
            process_id: process_id,
            sub_process_id: sub_process_id,
            graph_name: 'Process energy use (kWh)',
            plant_id:plant_id,
            plant_code:plant_code,
          }
        ];
      },
      id: 'processProcess'
    },
    {
      ref: peakRef,
      component: () => {
        return [
          MainInstantaneousPowerComponent,
          {
            drilldownlevel: 'ProcessLayer',
            process_type: process_type,
            process_id: process_id,
            sub_process_id: sub_process_id,
            graph_name: 'Instantaneous Power (kW)',
            plant_id:plant_id,
            plant_code:plant_code,
          }
        ];
      },
      id: 'peakProcess'
    },
    {
      ref: instantRef,
      component: () => {
        return [
          MainProcessInstantaneousPowerComponent,
          {
            drilldownlevel: 'ProcessLayer',
            process_type: process_type,
            process_id: process_id,
            sub_process_id: sub_process_id,
            graph_name: 'Equipment Instantaneous Power (kW)',
            plant_id:plant_id,
            plant_code:plant_code,
          }
        ];
      },
      id: 'instantaneousProcess'
    }
  ];
  let params = new URLSearchParams(document.location.search);
  let energy = params.get("energy");
  let machine = params.get("machine_status");
  let kpi = params.get("kpi");

    return (
    <>
          <div className="flex-btw mb-4">
        <h6 className="inner-breadcrumb w-25">
          <Link to={flexibleEquipmentEnabled=='true'?`/plant/${encodeURIComponent(parentPlantDetails?.name)}?process_type=plant&plant_id=${plant_id}&plant_code=${plant_code}`:"/home"}>
            <i className=" material-icons notranslate graphoptionicons flip-icon">forward</i>{parentPlantDetails?.name}
          </Link>
        </h6>
        <h4 className="font-light">{decodeURIComponent(process)}</h4>

        <div className="flex-c w-25 justify-content-end">
          <div className="me-3">
            <div className="process-nav-dropdown">
            {processList?.length > 1 ? (
                <Dropdown className="bt-dropdown-nav ">
                <Dropdown.Toggle size="sm" variant="secondary">
                  Processes
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {processList?.map((process, i) => {
                    return (
                      <Dropdown.Item key={`processdropdown${i}`}>
                        <NavLink
                          end
                          activeClassName={'bt-dropdown--active'}
                          to={
                            process.is_zone_enabled
                              ? `/layer_process/${process?.name}?process_type=${process.layer_type}&process_id=${process?.process_id}&plant_code=${process?.plant_code}&plant_id=${plant_id}
                                }&is_zone_enabled=${true}&energy=${energy}&machine_status=${machine}&kpi=${kpi}`
                              : `/layer_process/${process?.name}?process_type=${process.layer_type}&process_id=${process?.process_id}&plant_code=${process?.plant_code}&plant_id=${plant_id}&energy=${energy}&machine_status=${machine}&kpi=${kpi}`
                          }
                          onClick={ReactGA.event({
                            category: 'Process dropdown click',
                            action: 'Selected process from dropdown',
                            label: `Process ${encodeURIComponent(process.name)} selected`
                          })}>
                          {process.name}
                        </NavLink>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            ):('')}

            
            </div>
          </div>
          <ChangePageViewComponent pageView={pageView} setPageView={setPageView} />
        </div>
      </div>

            {pageView.energy ? (
                <MainLayoutComponent chartWindows={chartWindows} process={process} />
      ) : pageView.machine_status && isMachineStatusEnabled ? (
                        <MachineStatusDetailContainer
          processDetails={{
            drilldownlevel: 'ProcessLayer',
            process_type: process_type,
            process_id: process_id,
            sub_process_id: sub_process_id,
            plant_id:plant_id,
            plant_code:plant_code,
            layer_id:getLayerId({
              process_id:process_id,
            }),
            layer_type:getLayerType({
              process_id:process_id,
            })
          }}
        />
              ) : (
        <KpiMachineLayoutComponent
          processDetails={{
            drilldownlevel: 'ProcessLayer',
            process_type: process_type,
            process_id: process_id,
            sub_process_id: sub_process_id,
            plant_id:plant_id,
            plant_code:plant_code,
          }}
        />
      )}
    </>
  );
}

export default React.memo(ProcessLayerDetailComponent);
