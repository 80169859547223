/* eslint-disable no-unused-vars */
// import React from "react";
import React, { useEffect, useState } from 'react';
// import { Card, Row, Col } from 'react-bootstrap';
import useHttpApiCall from '../../../../services/useHttpApiCall';
// import './shopfloor.scss'
import CreateForm from './createForm';
import ResponsiveExample from './tableView';
import MachineStatusCustomizeContainer from '../../../machinestatus/containers/machine.status.customize.container';
import { useSelector } from 'react-redux';
// import {alarm} from './alarm/'
// import { FaEdit, FaTrash } from 'react-icons/fa';  // Font Awesome icons



const ShopFloorViewSlides = () => {

  const [showModal, setShowModal] = useState(false);  
  const [payloadEquipments, setPayloadEquipments] = useState(undefined);
  const [payloadSlides, setPayloadSlides] = useState(undefined);

  const [equipments, setEquipments] = useState([]);
  const [slides, setSlides] = useState([]);

  const [data] = useHttpApiCall(payloadEquipments)
  const [slideList] = useHttpApiCall(payloadSlides)

const [reloadData,setReloadDatas]=useState(false)

// const [openMachineStatusSlider, setOpenMachineStatusSlider] = useState(false);
// const [filterGraphId, setFilterGraphId] = useState(null);
// const [customizeUpdate, setCustomizeUpdate] = useState({ filterGraphId: null, update: false });
const customise_button_click = useSelector((state) => state.customiseButtonClick.customise_button_click);
const flexible_equipment=localStorage.getItem('flexible_equipment')=='true'?true:false
const plantCode=localStorage.getItem('plantCode')

  useEffect(() => {
    flexible_equipment?setPayloadEquipments({
      auth: true,
      url: 'v1/layers/equipments/',
      params:{
        plant_code:plantCode,
        layer_type:'company'
      }
    }):setPayloadEquipments({
      auth: true,
      url: 'v0/equipments/'
    });
    setPayloadSlides({
      auth: true,
      url: 'v0/shop-floor-view/slides/'
    });
  }, []);
  useEffect(() => {
    flexible_equipment?setPayloadEquipments({
      auth: true,
      url: 'v1/layers/equipments/',
      params:{
        plant_code:plantCode,
        layer_type:'company'
      }
    }):setPayloadEquipments({
      auth: true,
      url: 'v0/equipments/'
    });
 ;
  }, [reloadData])
  useEffect(()=>{
    if(data && data?.data){
      setEquipments(data?.data?.data)
    }
  },[data])
  useEffect(()=>{
    if(slideList && slideList?.data){
      setSlides(slideList?.data?.data)
    }
  },[slideList])
console.log('time diff........',data?.data?.data)

// useEffect(()=>{
//   console.log('time diff........',customise_button_click,openMachineStatusSlider)
// },[openMachineStatusSlider])
  // handleStart()

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const setReloadData=()=>{ setReloadDatas(!reloadData)}
useEffect(()=>{
  setPayloadSlides({
    auth: true,
    url: 'v0/shop-floor-view/slides/'
  });
},[reloadData])
  return (
    <div className="container mt-4">
      <iframe src="../alarm/emergency-alarm-with-reverb-29431.mp3" allow="autoplay" style={{display:'none'}} id="iframeAudio">
      </iframe> 
      <h4 className="text-center layout-heading">Slides</h4>


   <ResponsiveExample setReloadData={setReloadData} dataList={equipments} isSlides={true} tableData={slides} />
  </div>
 
  );
};

export default ShopFloorViewSlides;
