/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import {
  axisLablesFontSizeSet,
  chartChartConfig,
  chartCommonConfig,
  chartTitleConfig,
  chartYaxisConfig
} from '../../../config/chartCommon';
const _ = require('lodash');
import getTotalEnergyUseBarTooltop from '../utilities/main.total.energy.use.bar.tooltip';
import eventFire from '../../../utilities/eventFire-inside-highcharts';
function stackConfig({ chartApiData,xAxisCategoryLables, graphFilter, graphName, userAccessibility, navigate, props }) {
  
  const config = {
    ...chartTitleConfig({
      graphTitle: graphName,
      userAccessibility: userAccessibility
    }),
    ...chartCommonConfig,
    chart: {
      ...chartChartConfig({ userAccessibility: userAccessibility }),
      spacingTop: 10,
      spacingBottom: 15,
      type: 'column'
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom',
      y: 20,
      itemStyle: {
        fontWeight: 'lighter',
        fontSize: axisLablesFontSizeSet(userAccessibility)
      },
      reversed: true
    },
    plotOptions: {
      series:{
        clip: false
      },
      column: {
        minPointVal: 150,
        borderWidth: 0 ,
        stacking: 'normal',
        borderColor: null,
        states: {
          hover: {
           color: function() {
              return this.color; // Keeps the original color
          },
            // enabled:false,
            opacity: 0.9 // Change the opacity for hovered series
        },
          inactive: {
              opacity: 0.6 // Change the opacity for inactive series
          }
      },
      // hover: {
      //     opacity: 1 // Ensure hovered series remains fully opaque
      // },
        point: {
          events: {
            click: function (data) {
              console.log('data', props);
              if (props.drilldownlevel == 'Process') {
                navigate(
                  `${location.pathname}/${encodeURIComponent(
                    data.point.equipment_name
                  )}?&process_id=${data.point.process_id}&sub_process_id=${
                    data.point.sub_process_id
                  }&equipment_id=${
                    data.point.equipment_id
                  }&energy=false&kpi=false&machine_status=true`
                );
              }
            }
          }
        }
      }
    },
    colors: ['#d7ddfe', '#feedbd', '#f9d7d7', '#dcf9bd'],
    series: chartApiData && chartApiData.length == 0 ? [] : chartApiData,
    exporting: {
      chartOptions: {
        chart: {
          spacingBottom: 30
        },
        subtitle: {
          text: ''
        },
        plotOptions: {
          column: {
            stacking: 'normal'
          },
          series: {
            dataLabels: {
              format: '{point.y:,.0f}',
              style: {
                fontSize: graphFilter.filterMain != '1week' ? '9px' : '11px'
              }
            }
          }
        }
      }
    },

    xAxis: {
      lineColor: '#cccccc',
       categories: xAxisCategoryLables,
      //categories: chartApiData && [`sdfsfssfsdf`,`sfdsfdsfsdf`,`sfsdfsdfsdf`,`sfsdfsdfsdf`,`sdfsdfsdfs`,`sdfdsfsdfsfsdfs`,`sdfdsfsf`],
      tickLength: 0,

      //tickPixelInterval: 70,
      labels: {
         autoRotation: undefined,
        padding: 3,
        style: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          fontSize: axisLablesFontSizeSet(userAccessibility)
        },
        events: {
          click: function () {
            var xaxisLableElement = document.querySelectorAll(
              '.process-energy-use .highcharts-point'
            )[this.pos];
            eventFire(xaxisLableElement, 'click');
          }
        },
        // formatter: function () {
        //   return chartApiData && chartApiData[0].data[this.value].equipment_name;
        // }
      }
    },

    yAxis:{
        ...chartYaxisConfig({ userAccessibility }).yAxis,
        max:100,
        min:0,
    },
    tooltip: {
        style: {
            lineHeight: '17px',
        },
        // shared: true,
        // useHTML: true,
      formatter: function () {
     

        let avgKwh = this.point.avg_kwh != null? `<div class="txt-10">Avg: ${_.round(this.point.avg_kwh, 2)} kWh </div><br>` : '';
        let kWh = this.point.kwh != null ? `<div class="font-bold txt-10 pb-1">${_.round(this.point.kwh,2)} kWh</div>`: '';

        return `${this.point.equipment_name}<br><div class="txt-10 mb-3"><span style="color:${this.series.color}">\u25CF </span>${this.series.name}: <span class="font-bold">${Math.ceil(this.point.y * 100) / 100} %</span>(${this.point.hours}h)</div><br>${kWh}<br> ${avgKwh}<span class="txt-10">${this.point.reading_start_date} &#8212; ${this.point.reading_end_date}</span>`;
      },
      outside: true,
      positioner: function(labelWidth, labelHeight, point) {
        var x = Math.min(Math.max(point.plotX + this.chart.plotLeft - labelWidth / 2, this.chart.plotLeft), this.chart.plotWidth + this.chart.plotLeft - labelWidth);
        var y = point.plotY + this.chart.plotTop - labelHeight - 5;
        return { x: x, y: y };
      }
    }
  };
  return config;
}

export default stackConfig;
