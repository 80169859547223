/* eslint-disable no-unused-vars */
// /* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import useHttpApiCall from '../../../../services/useHttpApiCall';
import { Accordion, Button, Col, Container, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap';
import MyModal from './scheduleForm';
import alarm from './alarm/emergency-alarm-with-reverb-29431.mp3'
const Schemas=()=>{
    // const [schedulePayload, setSchedulePayload] = useState([]);
  const [payloadSlideShows, setPayloadSlideShows] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
    // const [payloadEquipments, setPayloadEquipments] = useState(undefined); 
  const [dataProps, setDataProps] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [editForm, setEditForm] = useState(false);
  const handleShow = () =>{
    setShowModal(true);
    setDataProps({})
  } 
  const handleClose =() =>{
      setShowModal(false);
      setEditForm(false)
      setDataProps({})
    } 
  
  const [deletePayload, setDeletePayload] = useState(undefined);  
  var deleteResponcedata= useHttpApiCall(deletePayload)
  // const [equipments, setEquipments] = useState([]);
  const [audio] = useState(new Audio(alarm));


  // const [data] = useHttpApiCall(payloadEquipments)
  const data1 = useHttpApiCall(payloadSlideShows)
     useEffect(() => {
        setPayloadSlideShows({
          auth: true,
          url: 'v0/shop-floor-view/schedules/'
        });

      }, []);
      useEffect(() => {
        // deleteResponcedata[0]?.status&&reloadData()
//         console.log(' deleteResponcedata[0]?.data.........', deleteResponcedata[0]?.status)
//         deleteResponcedata[0]?.status&& setPayloadSlideShows({
//           auth: true,
//           url: 'v0/shop-floor-view/schedules/'
//         });
// deleteResponcedata={}
      }, [deleteResponcedata]);
  // useEffect(() => {
  //   setPayloadEquipments({
  //     auth: true,
  //     url: 'v0/equipments/'
  //   });

  // }, []);
  // useEffect(()=>{
  //   if(data && data?.data){
  //     setEquipments(data?.data?.data)
  //   }
  // },[data])
  const reloadData=()=>{
    setPayloadSlideShows({
      auth: true,
      url: 'v0/shop-floor-view/schedules/'
    });
  }
  // useEffect(()=>{
  //   console.log('dataxxxxxxxx delete...........',deleteResponcedata)
  // },[deleteResponcedata])
  //     useEffect(()=>{
  //       console.log('dataxxxxxxxx...........',dataProps)
  //     },[dataProps])
        // Handle button click for weekdays
const handleAddClick=(item)=>{
  console.log('dataxxxxxxxx...........',item)
  if(item==null){
    setDataProps({})
    setShowModal(true)
    setEditForm(false)
  }
  else{
    setDataProps(item)
    setShowModal(true)
    setEditForm(true)
  }
 

}
const handleDeleteClick=(item)=>{

setDeletePayload({
      auth: true,
      url: `v0/shop-floor-view/schedules/${item?.id}/`,
      method: 'DELETE'
    })
  }
  
  
  const handleEditClick=(item)=>{
  console.log('dataxxxxxxxx...........',item)

    setDataProps(item)
    setShowModal(true)
    setEditForm(true)
    
    }



  
      // Create a new audio object
      // const audio = new Audio('../alarm/emergency-alarm-with-reverb-29431.mp3'); // Replace with your sound file path
      const playSound = () => {
        audio.play();
      };
    
const CardView=({item,index})=>{
  return(
    <div key={index}>
      <Row className='cards  p-3 pt-3'>
        <Col xs={10} aria-disabled >
          <input name='name'  className='shadow rounded form-control shadow border-0 py-4 my-3 rounded clientInput schedule_name' disabled style={{marginBottom:'10px'}} value={item?.name}/>
          <div className="d-flex flex-wrap day_button_container my-3">
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day,index) => (
              <Button
              key={day}
              variant={item.schedule_time?.map((schedule_days)=>schedule_days?.schedule_day)?.includes(index+1) ? 'success' : 'secondary'}
              className="m-1 day_button"
              disabled
              >
            {day.charAt(0).toUpperCase() + day.slice(1)}
            </Button>
            ))}
          </div>
        {/* --------------------------------timer----------------------------- */}
        <div>
        {/* Time range */}
          <Form.Group controlId="formTime" className='my-3'>
            <Form.Label className='d-block'>Enter Time</Form.Label>
            <Form.Control
            type="time"
            className='mr-5'
            value={item?.schedule_time?.[0]?.schedule_from_time}
            disabled
            // onChange={(e) => handleTimeChange(e, 'schedule_from_time')}
            />
            <Form.Control
            type="time"
            value={item?.schedule_time?.[0]?.schedule_to_time}
            disabled
            // onChange={(e) => handleTimeChange(e, 'schedule_to_time')}
            />
          </Form.Group>
        </div>
          <div className='shopfloor-quotes my-3 pt-3'>
          Select machine state <span>
          (Machine state is based on machine status setting)
          </span>
          </div>
        <div>
          <Form.Group controlId="formStatus">
          {/* <h5>Status</h5> */}
            <DropdownButton
            variant="secondary"
            name='machine_status'
            id="dropdown-basic-button"
            title={item?.machine_status?.charAt(0).toUpperCase() + item?.machine_status?.slice(1)}
            // onSelect={handleDropdownChange}
            // value={scheduleParams?.machine_status}
            defaultValue={'OFF'}
            disabled
            >
              <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
              {item?.machine_status}
              </Dropdown.Toggle>
              <Dropdown.Item eventKey="PRODUCTION">PRODUCTION</Dropdown.Item>
              <Dropdown.Item eventKey="OFF">OFF</Dropdown.Item>
              <Dropdown.Item eventKey="SLEEP">SLEEP</Dropdown.Item>
              <Dropdown.Item eventKey="STANDBY">STANDBY</Dropdown.Item>
            </DropdownButton>

          </Form.Group>
          <Form.Group controlId="formStatus" className='d-flex align-items-center my-3'>
            <h5 className='schedule-label'>Should be</h5>
            <div>
            {/* <label className="mt-4 mb-2  modal-label">Machine Status condetion</label> */}
              <div className="text-left px-3" disabled>
                <label className="daz-switch  mt-1">
                  <input
                  type="checkbox"
                  // onChange={() => { handleChange({ target: { name: 'machine_status_condition', value: !scheduleParams.machine_status_condition } }) }}
                  disabled
                  checked={item?.machine_status_condition=='SHOULD_BE'}
                  />
                  <span disabled className="slider round"></span>
              </label>
              </div>
            </div>
            <h5 className='schedule-label'>Should not be</h5>
            <div>
            {/* <label className="mt-4 mb-2  modal-label">Machine Status condetion</label> */}
              <div className="text-left px-3" disabled>
                <label className="daz-switch mt-1">
                  <input
                  type="checkbox"
                  // onChange={() => { handleChange({ target: { name: 'machine_status_condition', value: !scheduleParams.machine_status_condition } }) }}
                  disabled
                  checked={!item?.machine_status_condition=='SHOULD_BE'}
                  />
                  <span disabled className="slider round"></span>
              </label>
              </div>
            </div>

          </Form.Group>
        </div>

        </Col>
        <Col xs={2}>
          <i className="small material-icons material-symbols-outlined notranslate card_icons edit_icon" onClick={()=>{handleEditClick(item)}}>edit_square</i>
          <i className="small material-icons material-symbols-outlined notranslate card_icons delete" onClick={()=>{handleDeleteClick(item)}}>delete</i>
          {/* <i className="small material-icons material-symbols-filled notranslate card_icons add_icon" onClick={()=>{handleAddClick(item)}}>add_circle</i> */}
        </Col>

      </Row>

    </div>)

}




return(
<div className='schemas'>
<MyModal show={showModal}  handleClose={()=>{handleClose(),reloadData()}} formDataProps={editForm?dataProps:{}} isEdit={editForm}/>


<div className='addNewButton'>
<Button className='btn-grad btn btn-primary ' onClick={()=>{handleAddClick(null)}}>Add</Button>

</div>


{
data1[0]?.data?.data?.map((item,index)=> (
<div key={index}>
<Accordion className='linkAccordion mt-3'>
<Accordion.Item className='base-grey w-auto' eventKey="0">
<Accordion.Header className='w-auto'>  <div className='active setting_option shadow schedule_header w-100'>
<h4>{item?.name}</h4>
</div></Accordion.Header>
<Accordion.Body>
<Container className='bg-transparent shopfloor-previous-list '>     
<CardView item={item} index={index}/>           
{
//  data1[0]?.data?.data?.map((item,index) =>{
// return cardView(item,index)
// })

}
</Container>

</Accordion.Body>
</Accordion.Item>
</Accordion>
</div>))
}

</div>
)
}
export default Schemas