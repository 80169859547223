/* eslint-disable no-unused-vars */
// import React from "react";
import React, { useEffect, useState } from 'react';
// import { Card, Row, Col } from 'react-bootstrap';
import useHttpApiCall from '../../../../services/useHttpApiCall';
// import './shopfloor.scss'
import CreateForm from './createForm';
import ResponsiveExample from './tableView';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
// import { SlidesData } from './data';
// import {alarm} from './alarm/'
// import { FaEdit, FaTrash } from 'react-icons/fa';  // Font Awesome icons



const ShopFloorView = () => {

  // const [scheduleModal,setScheduleModal]=useState(true) const [showModal, setShowModal] = useState(false);
  // const [scheduleModal,setScheduleModal]=useState(true) const [showModal, setShowModal] = useState(false);
  const [showModal, setShowModal] = useState(false);  
  const [payloadSlideShows, setPayloadSlideShows] = useState(undefined);
  const [payloadSlides, setPayloadSlides] = useState(undefined);

  const [slideShows, setSlideShows] = useState([]);
  const [slides, setSlides] = useState([]);

const [reloadData,setReloadDatas]=useState(false)
  const [data] = useHttpApiCall(payloadSlideShows)
  const slideList = useHttpApiCall(payloadSlides)

  const setReloadData=()=>{ setReloadDatas(!reloadData)}

  useEffect(() => {
    setPayloadSlideShows({
      auth: true,
      url: 'v0/shop-floor-view/slide-shows/'
    });
    setPayloadSlides({
      auth: true,
      url: 'v0/shop-floor-view/slides/'
    });
  }, [reloadData]);
  useEffect(() => {
    setPayloadSlideShows({
      auth: true,
      url: 'v0/shop-floor-view/slide-shows/'
    });
    setPayloadSlides({
      auth: true,
      url: 'v0/shop-floor-view/slides/'
    });
  }, []);
  useEffect(()=>{
    if(data && data?.data){
      setSlideShows(data?.data?.data)
    }
  },[data])
  useEffect(()=>{
    if(slideList[0] && slideList[0]?.data){
      setSlides(slideList[0]?.data?.data)
    }
  },[slideList])


  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  useEffect(()=>{
    setPayloadSlideShows({
      auth: true,
      url: 'v0/shop-floor-view/slide-shows/'
    });
  },[showModal])

  return (
    <div className="container mt-4">
      <iframe src="../alarm/emergency-alarm-with-reverb-29431.mp3" allow="autoplay" style={{display:'none'}} id="iframeAudio">
      </iframe> 
      <h4 className="text-center layout-heading">Slde show</h4>


   <ResponsiveExample setReloadData={setReloadData} dataList={slides} isSlides={false} tableData={slideShows}/>
   
  </div>
 
  );
};

export default ShopFloorView;
// const ShopFloorView=()=>{
//     return (
//         <div>shop</div>
//     )
// }
// export default ShopFloorView