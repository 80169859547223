import SummarygGraphComponents from './summarygraph.components';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { setSidebarClick } from '../../../redux/sidebar/sidebar.action';
import { useSelector, useDispatch } from 'react-redux';
import ContactSupportContainer from '../../../modules/contactsupport/containers/contactsupport.container';
import ProcessMenuComponent from '../../sidebar/components/process-menu.component';
import { useEffect, useState } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import SensorMenuComponent from '../../sidebar/components/sensors-menu.component';
export default function HeaderComponents({ setModalShow, modalShow }) {
  const location = useLocation();
  const navigate = useNavigate();
  const sidebarClick = useSelector((state) => state.sidebarClick.linkClick);
  const dispatch = useDispatch();
  const [payload,setPayload]=useState(undefined)
  const [isOperator] = useState(localStorage.getItem('is_operator')== 'true' ? true : false) ;
  const regex = /^\/plant\/[^/]+$/;

  const [data] = useHttpApiCall(payload);
  useEffect(()=>{
    if(data?.status==200){
      localStorage.clear();
      window.location.reload();
    }   
  },[data])
  const onLogoClick = () => {
    dispatch(setSidebarClick(!sidebarClick));
  };
  const set_equipment_filter = useSelector((state) => state.equipmentFilter.set_equipment_filter);
  useEffect(() => {
    console.log('redux set equipment filter in header', set_equipment_filter);
  }, [set_equipment_filter]);
  return (
    <header className="p-20 daz-header">
      <div className="daz-header__logo ">
        <Link to="/home" onClick={onLogoClick}>
          <img src="../../../dazoq-logo-black.png" alt="Dazok Logo"></img>
        </Link>
      </div>
      <div>{location.pathname == '/home'||location.pathname == '/company'||regex.test(location.pathname ) ? <SummarygGraphComponents /> : ''}</div>
      <div style={{marginRight:'20px'}} className="d-flex ">
        {/* <Toast
          style={
            isOperator == 'true'
              ? { position: 'absolute', right: '244px', width: '50%' }
              : location.pathname == '/home'
              ? { position: 'absolute', right: '144px' }
              : { position: 'absolute', right: '144px', width: '50%' }
          }>
          <Toast.Body>
            We’re currently experiencing an issue with the visualization of some sensor data. The
            correct data is still available and will soon be displayed correctly. We apologize for
            the inconvenience.
          </Toast.Body>
        </Toast> */}
      

     
        {
          isOperator && <div className="daz-header__processwrap ms-2" >
            <div className="position-relative daz-header__hoverwrp">
              <a className="d-block text-center" href="#">
                <i className="medium material-icons notranslate">equalizer</i>
                {/* <i className="small material-icons notranslate">equalizer</i> */}
                <span className="txt-14">Add-ons</span>
              </a>
              <SensorMenuComponent />
            </div>
          </div>
        }
           <div className="daz-header__processwrap ms-2">
          <div className="position-relative daz-header__hoverwrp">
            <a className="d-block text-center" href="#">
              <i className="small material-icons notranslate">account_tree</i>
              <span className="txt-14">Processes</span>
            </a>
            <ProcessMenuComponent />
          </div>
        </div>
        <div className="daz-header__profilewrap ms-2">
          <div onClick={() => setModalShow(true)} className="pointer text-center support-icon">
            <i className="small material-icons notranslate">contact_support</i>
            <span className="txt-14">Support</span>
          </div>
        </div>

        <Dropdown className="daz-header__dropdown">
          <Dropdown.Toggle>
            <div className="daz-header__profilewrap ">
              <i className="small material-icons notranslate">face</i>
              <span className="txt-14">Profile</span>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              <button
                className="daz-header__logout text-start"
                onClick={() => {
                  setPayload({
                    auth: true,
                    url: 'v0/api/users/logout/',
                    method:'POST',
                  });
                  localStorage.clear();
                  window.location.reload();
                }}>
                Logout
              </button>
            </Dropdown.Item>
            <Dropdown.Item className="daz-header__dropdownProfile">
              {' '}
              <button className="text-start " onClick={() => navigate('/profile')}>
                Profile
              </button>
            </Dropdown.Item>
     
          </Dropdown.Menu>
        </Dropdown>
        
        {<ContactSupportContainer modalShow={modalShow} setModalShow={setModalShow} />}
      </div>
    </header>
  );
}
