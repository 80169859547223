const initialState = {
  customise_button_click: {}
};

const customiseButtonReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CUSTOMISE_BUTTON':
      return {
        ...state,
        customise_button_click: action.payload
      };
    default:
      return state;
  }
};

export default customiseButtonReducer;
