/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import { Modal, Button} from 'react-bootstrap';
import CreateForm from './createForm';
// import { sampleData } from './data';

const SlideForm = ({ show, handleCloseModal,heading,slides ,dataList,editForm,editData,setReloadData}) => {



  return (
    <Modal show={show} onHide={handleCloseModal} className='shopfloorForm' >
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
     <CreateForm itemList={dataList} handleCloseModal={handleCloseModal} slides={slides} editForm={editForm} editData={editData} handleSubmit={(e)=>{console.log('xxxxxxxxxxxx........submit',e)}}setReloadData={setReloadData}/>
      </Modal.Body>
    </Modal>
  );
};

export default SlideForm;