/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { dataArray } from './data';
import { Card, Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import useHttpApiCall from '../../../../services/useHttpApiCall';
import moment from 'moment';

export const SlideShow=()=>{
    
      const [tasks, setTasks] = useState([]);
      const [canPlaySound, setCanPlaySound] = useState(false);
//       const [alarmPlaying, setAlarmPlaying] = useState(false); // Track if the alarm is playing
// const onlineAudioUrl = "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"; // Replace with your online audio URL
const [slideshowPayload, setSlideshowPayload] = useState(undefined);  
const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
const [currentSlide, setCurrentSlide] = useState(0);
const [startSlideShow,setStartSlideShow]=useState(true)
const [isFullscreen, setIsFullscreen] = useState(false);
const [isElementVisible, setIsElementVisible] = useState(false);

const [lastTimeStamp, setLastTimeStamp] = useState(null);
const TIME_TO_TRIGGER= 30 * 1000 
const EXTRATIME_TO_TRIGGER=120000
let addPointApiTimer, loadingTimer;

const [data] = useHttpApiCall(slideshowPayload)
  const { id } = useParams();
        // Create audio instance for alarm sound
  // const alarmSound = new Audio(onlineAudioUrl);


  // Next and Prev button functionality
  const nextSlide = () => {
    setCurrentSlideIndex((prevIndex) =>
      prevIndex <tasks?.tagged_slides?.length - 1 ? prevIndex + 1 : 0
    );
  };

  const prevSlide = () => {
    setCurrentSlideIndex(
      (prevIndex) => (prevIndex > 0 ? prevIndex - 1 : tasks.tagged_slides.length - 1)
    );
    
  };

  // Automatically move to the next slide every second
  useEffect(() => {
    const interval = setInterval(nextSlide, 2000); // 1 second
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);


 useEffect(() => {
    if (lastTimeStamp) {
      let lastTime = moment(lastTimeStamp);
      let diffrenceTime = moment().diff(lastTime); // Time left from current time to last updated
      console.log('difference time ', diffrenceTime, 'last time stamp', lastTimeStamp);

      if (diffrenceTime > TIME_TO_TRIGGER) {
        //console.log('difference time is more', diffrenceTime);
        diffrenceTime = TIME_TO_TRIGGER;
      }
      addPointApi(TIME_TO_TRIGGER, diffrenceTime);
    }
    return () => {
      clearTimeout(addPointApiTimer);
    };
  }, [lastTimeStamp, data])
  const addPointApi = (triggertime, diffrenceTime) => {
    clearTimeout(loadingTimer);
    clearTimeout(addPointApiTimer);
    let timeOutTime = triggertime - diffrenceTime + EXTRATIME_TO_TRIGGER;
    addPointApiTimer = setTimeout(function () {
      // setCheckOfflineCut(true);
      graphAddPointApiCall();
    }, timeOutTime);
  };





  // Play online audio if conditions are met
  // const playAlarm = () => {
  //   if (!alarmPlaying) {
  //     alarmSound.play().catch((error) => {
  //       console.log('Error playing audio:', error);
  //     });
  //     setAlarmPlaying(true); // Update state to indicate alarm is playing
  //   }
  // };

  // Stop the alarm sound after 10 seconds
  // const stopAlarm = () => {
  //   setTimeout(() => {
  //     alarmSound.pause(); // Stop the audio
  //     alarmSound.currentTime = 0; // Reset to start
  //     setAlarmPlaying(false); // Update state to indicate alarm is no longer playing
  //   }, 10000); // Stop after 10 seconds
  // };

  // Update card shadows every 3 minutes
  // const updateCardShadows = () => {
  //   const updatedTasks = tasks?.map((task) => {
  //     const isLessThanOneMinute = calculateTimeDifference(task.timestrap);
  //     return {
  //       ...task,
  //       // boxShadowColor: isLessThanOneMinute ? 'red' : '', // Red shadow if time difference is less than 1 minute
  //       blinkEffect: isLessThanOneMinute,
  //     };
  //   });
  //   setTasks(updatedTasks);

  //   // Start alarm if time difference is less than 1 minute and stop after 10 seconds
  //   if (canPlaySound) {
  //     tasks.forEach((task) => {
  //       if (calculateTimeDifference(task.timestrap)) {
  //         playAlarm(); // Play alarm sound
  //         stopAlarm(); // Stop the alarm after 10 seconds
  //       }
  //     });
  //   }
  // };
useEffect(()=>{
  setCanPlaySound(true)
},[])
  // Trigger checking every 3 minutes
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     updateCardShadows(); // Update shadows every 3 minutes
  //   }, 60 * 1000);

  //   // Clean up interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, [tasks, canPlaySound]);

useEffect(()=>{
  setSlideshowPayload(
    {
      auth: true,
      url: `v0/shop-floor-view/slide-shows/${id}/show/`
    }
  )
},[])
const graphAddPointApiCall=()=>{
  setSlideshowPayload({
    auth: true,
    url: `v0/shop-floor-view/slide-shows/${id}/show/`,
    params: {
      last_reading_time: lastTimeStamp,
    }
  });
}
useEffect(()=>{
  const currentTime = new Date().getTime();
setLastTimeStamp(currentTime)
  console.log('slide show list..........',currentTime)
  // try {
  //   // Make the API call with the appropriate params
  //   const response = await axios.get('https://your-api-endpoint.com', { params });
  //   console.log('API response:', response.data);

  // } catch (error) {
  //   console.error('Error making API call:', error);
  // }

  setCurrentSlide(data?.data?.data?.tagged_slides?.[0])
  setTasks(data?.data?.data)
},[data])
 useEffect(()=>{
  console.log('current.....................',currentSlide)
 },[currentSlide])
 useEffect(()=>{
  setCurrentSlide(tasks?.tagged_slides?.[currentSlideIndex])
  console.log('current.....................',currentSlide)
 },[currentSlideIndex])
    // const calculateTimeDifference = (timestrap) => {
    //   const taskTime = new Date(timestrap).getTime();
    //   const currentTime = new Date().getTime();
    //   const timeDiff = currentTime - taskTime;
    //   const oneMinute = 20*60 * 60 * 1000; // 1 minute in milliseconds
    //   return timeDiff <= oneMinute; // Returns true if time difference is less than 1 minute
    // };
    function formatTime(timestamp) {
        const date = new Date(timestamp);
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return date.toLocaleTimeString([], options); // Format to only show time
      }

      const handleFullscreen = () => {
        const el = document.getElementById('element');
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.mozRequestFullScreen) { // Firefox
          el.mozRequestFullScreen();
        } else if (el.webkitRequestFullscreen) { // Chrome, Safari and Opera
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) { // IE/Edge
          el.msRequestFullscreen();
        }
        setIsElementVisible(true);
        setIsFullscreen(true);
      };
      const handleExitFullscreen = () => {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { // Chrome, Safari, and Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { // IE/Edge
          document.msExitFullscreen();
        }
    
        setIsElementVisible(false); // Hide the element when exiting fullscreen
        setIsFullscreen(false);
      };
    
    return(
<div className='slideShowContainer'>
{/* <div className="container"> */}
  {/* <p><button onClick="var el = document.getElementById('element'); el.requestFullscreen();">
    Go Full Screen!
  </button></p>

</div> */}
  {
    <div className='element p-3 '  id="element"    style={{
      display: isElementVisible ? 'block' : 'none', 
       backgroundColor: isFullscreen ? 'white' : 'white',
       transition: 'background-color 0.3s',
     }}>
      <div>
      <button type="button" className="btn-close" aria-label="Close" onClick={()=>{setStartSlideShow(false),setIsElementVisible(false),handleExitFullscreen()}}></button>
      </div>
    <div style={{ textAlign: 'center' }}>
        <h1 className='mb-3'>{tasks?.name}</h1>
    
        <Row  >
        {tasks?.tagged_slides?.map((task, index) => 
       index==currentSlideIndex&& task?.tagged_equipment?.length>0? task?.tagged_equipment?.map(item=>
            (
              <Col key={index} className="mb-4  mx-2" sm={12} md={4} lg={3} >
              <Card className={`${item?.is_schedule_criteria_met&&'flashing-border card'}`} style={{ boxShadow: `0px 4px 8px 0px `, transition: 'box-shadow 0.3s ease' }}>
                <Card.Body style={{minHeight:'190px'}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <Card.Title>
                      <div>
                      {item?.equipment_name}
                      </div>
                    </Card.Title>
                  
                  </div>
                  <Card.Text className='shop-floor-value'>
                    {item.kw_value} kW
                  </Card.Text>
                  <Card.Text className='shop-floor-description'>
                   {task.description}
                  </Card.Text>
                  {
                    <i className=" material-icons notranslate graphoptionicons delete"  style={{opacity:`${item?.is_schedule_criteria_met?1:0}`}} onClick={()=>{setStartSlideShow(true)}}>warning</i> 
                  }
      

                  <Card.Text>
                  <div className='d-flex justify-content-between'>
     {/* <span>machine_status</span> */}
     <span>{item?.machine_status}</span>
  
                    {/* <small style={{ color: `0px 4px 8px 0px `, transition: 'box-shadow 0.3s ease' }} className="text-muted">{formatTime(item.machine_status)}</small> */}
                    </div>
                    {/* <div className='text-end'>
                    <small style={{ color: `0px 4px 8px 0px `, transition: 'box-shadow 0.3s ease' }} className="text-muted">{formatTime(item.machine_status)}</small>
     
                    </div> */}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            )
           
           ):(
            <div key={index}> {  index==currentSlideIndex&&'No Data'}</div>
           )
        //   return(
         
        // )
        )}
      </Row> 
  
        {/* Control Buttons */}
       
      </div>
  
    </div>
  }
 






 <div className="d-flex justify-content-between">
 <h6 className="inner-breadcrumb">
        <Link to={'/profile'}>
          {' '}
          <i className=" material-icons notranslate graphoptionicons flip-icon">forward</i>Back
        </Link>
      </h6>
      <span className='d-flex' style={{'alignItems':'center'}}>
      Preview
      <i className=" material-icons notranslate graphoptionicons " onClick={handleFullscreen}>play_arrow</i> 

      </span>
 </div>

      <div className='row'>
      <div className='col-2'></div>
        <div className='col-8'>
        <h4 className="text-center layout-heading">{tasks?.name}</h4>

        </div>
        <div className='col-2'>
        <div className="me-3">
      
          </div>
        </div>
      </div>
   
      {/* =========================card list======================== */}
      <Row  >
      {tasks?.tagged_slides?.map((task, index) => 
     
         task?.tagged_equipment?.length>0&& task?.tagged_equipment?.map(item=>
          (
            <Col key={index} className="mb-4  mx-2" sm={12} md={4} lg={3} >
            <Card className={`${item?.is_schedule_criteria_met&&'flashing-border card'}`} style={{ boxShadow: `0px 4px 8px 0px `, transition: 'box-shadow 0.3s ease' }}>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <Card.Title>
                    <div>
                    {item?.equipment_name}
                    </div>
                  </Card.Title>
                
                </div>
                <Card.Text className='shop-floor-value'>
                  {item.kw_value}kW
                </Card.Text>
                <Card.Text className='shop-floor-description'>
                 {task.description}
                </Card.Text>
                <Card.Text>
                <div className='d-flex justify-content-between'>
   {/* <span>machine_status</span> */}
   <span>{item?.machine_status}</span>

                  {/* <small style={{ color: `0px 4px 8px 0px `, transition: 'box-shadow 0.3s ease' }} className="text-muted">{formatTime(item.machine_status)}</small> */}
                  </div>
                  {/* <div className='text-end'>
                  <small style={{ color: `0px 4px 8px 0px `, transition: 'box-shadow 0.3s ease' }} className="text-muted">{formatTime(item.machine_status)}</small>
   
                  </div> */}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          )
         
         )
      //   return(
       
      // )
      )}
    </Row> 
    {/* ------------------------------------------------------------- */}
</div>

    )
}