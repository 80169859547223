// import Table from 'react-bootstrap/Table';
// import {  slideShows } from './data';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SlideForm from './slideForm';
import useHttpApiCall from '../../../../services/useHttpApiCall';

function ResponsiveExample({isSlides,dataList,tableData,setReloadData=()=>{}}) {
      const [showModal, setShowModal] = useState(false);  
      const [deletePayload, setDeletePayload] = useState(undefined);  
      const [editForm,setEditForm]=useState(false)
      const [editData,setEditData]=useState({})

  const [data] = useHttpApiCall(deletePayload)
    
      const navigate = useNavigate();
      const handleShow = () => {
        setShowModal(true)
        setEditData({})
        setEditForm(false)
      };
  const handleClose = () => setShowModal(false);
    // const handleEdit=()=>{}
      // console.log('xxxxxx.........',dataList,tableData)

      useEffect(()=>{
        data?.status==204&& setReloadData()
      },[data])
    const handleDelete=(item)=>{

      isSlides?setDeletePayload({
        auth: true,
        url: `v0/shop-floor-view/slides/${item?.id}/`,
        method: 'DELETE'
      }):setDeletePayload({
        auth: true,
        url: `v0/shop-floor-view/slide-shows/${item?.id}/`,
        method: 'DELETE'
      })
    }
    const truncateText = (text) => {
      // If the text length exceeds the max length, truncate and add "..."
      // if (text.length > maxLength) {
      //   return text.slice(0, maxLength) + "...";
      // }
      return text;
    };
  return (
  <div className='shopfloor '>
    <div className=''>

    <Row className='pt-2 pb-2 shopfloor-row' style={{borderBottom:'1px solid red'}}>    
<Col className='ps-0' lg={2}>
    <span className='semi-bold'>Name</span>    </Col> 
<Col className='ps-0 semi-bold'>   {!isSlides?'Slides':'Equipments'} </Col>
<Col className='ps-0 semi-bold' lg={1}> </Col>

</Row>

{
          isSlides?tableData?.map((item)=>
                <Row key={item?.id} className='pt-2 pb-2 each-prev-emission-factor shopfloor-row'>
                  
            <Col className='boldNames ps-0' lg={2}>{item?.name}</Col>
            <Col>
              {
                item?.equipment?.length>0?
                item?.equipment?.map((slide)=><div className='itemSlides' key={slide?.source_mapping}>{truncateText(slide?.equipment_name)},</div>):
                <div className='itemSlides'>No equipments...</div>
              }
            </Col>
            <Col align='center' lg={2}>
              <i className="medium material-icons notranslate itemIcons editIcon" onClick={()=>{handleShow()
                setEditData(item)
                setEditForm(true)
              }}>edit</i>
            <i className="medium material-icons notranslate itemIcons delete" onClick={()=>handleDelete(item)}>delete</i>

            </Col>

           

                </Row>
        ):   tableData?.map((item)=>
            <Row key={item?.id} className='pt-2 pb-2 each-prev-emission-factor  shopfloor-row'>
        <Col className='boldNames' lg={2}>{item?.name}</Col>
        <Col >
        {
item?.slides.length>0?
item?.slides?.map((slide)=><div className='itemSlides' key={slide?.slide}>{slide?.slide_name}{', '}</div>):
<div className='itemSlides'>No slides...</div>

}
        </Col>
        <Col align='right' lg={3}>
        <i className="medium material-icons notranslate itemIcons editIcon" onClick={()=>{handleShow()
setEditData(item)
              setEditForm(true)
            }}>edit</i>

<i className="medium material-icons notranslate itemIcons delete" onClick={()=>handleDelete(item)}>delete</i>
<i className="medium material-icons notranslate itemIcons editIcon" onClick={()=>{  navigate(`/slideShow/${item?.id}`)}}>play_arrow</i>

        </Col>
  {/* {!isSlides&& <td align='right'  ><Button style={{marginRight:'15px'}}className='btn-grad btn btn-primary' onClick={()=>{  navigate('/slideShow')}}>Start</Button></td>} */}

       

            </Row>
    )
        }



    <div className='addNewButton'>
   <Button className='btn-grad btn btn-primary ' onClick={handleShow}>Add</Button>

   </div>
    </div>
    <SlideForm show={showModal} editForm={editForm} editData={editData} dataList={dataList}  handleCloseModal={handleClose} slides={isSlides} heading={isSlides?'Create Slide':'Create slide show'} setReloadData={setReloadData}/>
 
    </div>
  );
}

export default ResponsiveExample;

