import React, { useEffect, useState } from 'react';
import {  Alert, Button, Col, Container, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import useHttpApiCall from '../../../../services/useHttpApiCall';
const CreateForm = ({ itemList,slides,editForm,editData,setReloadData,handleCloseModal }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [payload, setPayload] = useState();
  const [payload1, setPayload1] = useState();
  const [data2, loading2, error2] = useHttpApiCall(payload1);
  const [showToast, setShowToast] = useState(false);
  const [customiseModalVisibility,setCustomiseModalVisibility]=useState(false)

  useEffect(()=>{
  if(slides){
  editData?.equipment?.length>0&& setSelectedItems(editData?.equipment?.map((item)=>item?.source_mapping))
  }
  else{
  editData?.slides?.length>0&& setSelectedItems(editData?.slides?.map((item)=>item?.slide))
  }
  },[itemList])
useEffect(()=>{
  data2?.status==200&&setReloadData()
  data2?.status==200&&handleCloseModal()
},[data2])
// Handle item click
  const handleItemClick = (e,item) => {
  e.target.checked?setSelectedItems((prev)=>[...prev,item?.id]):setSelectedItems(selectedItems?.filter(prevItem=>prevItem!==item?.id))
  };
  useEffect(()=>{
  editForm&&setPayload(editData?.name)
  },[editForm])

  const handleSubmit=()=>{
  editForm?
  slides?  setPayload1({
  auth: true,
  url: `v0/shop-floor-view/slides/${editData?.id}/`,
  method:'PUT',
  data:{
  name:payload,
  is_active:true,
  tagged_equipment:selectedItems
  }

  }):
  setPayload1({
  auth: true,
  url: `v0/shop-floor-view/slide-shows/${editData?.id}/`,
  method:'PUT',
  data:{
  name:payload,
  is_active:true,
  tagged_slides:selectedItems
  }

  }):
  slides?  setPayload1({
  auth: true,
  url: 'v0/shop-floor-view/slides/',
  method:'POST',
  data:{
  name:payload,
  is_active:true,
  tagged_equipment:selectedItems
  }

  }):
  setPayload1({
  auth: true,
  url: 'v0/shop-floor-view/slide-shows/',
  method:'POST',
  data:{
  name:payload,
  is_active:true,
  tagged_slides:selectedItems
  }

  })

  }

  const handleChange=(e)=>{
  setPayload(e.target.value)
  }
  useEffect(()=>{
  data2?.data?.status==1&& setShowToast(true)
  console.log('vvvvvv1........',data2?.data?.status, loading2, error2)

  },[data2, loading2, error2])

  const truncateText = (text, maxLength) => {
  // If the text length exceeds the max length, truncate and add "..."
  if (text.length > maxLength) {
  return text.slice(0, maxLength) + "...";
  }
  return text;
  };

  const handleClose=()=>{
  setCustomiseModalVisibility(false);
  setReloadData()
  }

  return(
    <div className='shopfloorForm'>
      {/* ========== delete data */}
      <Modal show={customiseModalVisibility} onHide={handleClose} >
      <Modal.Body className='customiseModalForm'>

      </Modal.Body>

      </Modal>
      <Alert
      className="sidebar-slider_alert"
      show={showToast}
      variant="success"
      onClose={() => setShowToast(false)}>
      <p className="text-center">
      Data created successfully
      {/* {editMode ? 'Alert updated successfully' : 'Alert created successfully'} */}
      </p>
      </Alert>
      <div className='modalLabel mb-2'>
      Name
      </div>
      <input name='name' className='shadow rounded' value={payload}style={{minHeight:'35px',marginBottom:'10px'}} onChange={handleChange}></input>
      <h4  className='my-3'>{slides?'Equipment List':'Slides'}</h4>
      <div className='optionList'>
      <Container>
      {
      itemList?.length>0&&itemList?.map((item)=>(
      <div  key={item?.id} className='py-3 listItem' >
      <Row  className='formListItem my-1'>
      <OverlayTrigger
      placement="bottom"  // Position the tooltip at the bottom of the Col
      overlay={<Tooltip id="tooltip-bottom">{slides? item?.equipment_name:item?.name}</Tooltip>}
      >
      <Col xs={4} title={slides? item?.equipment_name:item?.name}> { truncateText(slides? item?.equipment_name:item?.name)}</Col>

      </OverlayTrigger>
      <Col xs={6}>


      </Col>
      <Col className="d-flex justify-content-end align-items-center">
      <input className="form-check-input " type="checkbox" checked={selectedItems?.includes(item?.id)} onChange={(e)=>{handleItemClick(e,item)}}></input>
      </Col>

      </Row>
      </div>



      )

      )
      }
      </Container>
      </div>
      <div>

      </div>
      <div className='addNewButton'>
      <Button className='mt-4 btn-grad btn btn-primary' onClick={()=>{handleSubmit()}}>{editForm?'Update':'Submit'}</Button>

      </div>

    </div>
  )
}
export default CreateForm